<template>
  <!-- ssr下因为mount方法调用时标签已被渲染，所以将app id放在这里-->
  <div id="app">
    <router-view></router-view>
  </div>
</template>

<script>
import { mapActions, mapGetters } from 'vuex';
import { errorMonitorMixin } from '@/utils/errorReporter';

export default {
  name: 'App',
  mixins: [errorMonitorMixin],
  computed: {
    ...mapGetters(['isUserDataChecked'])
  },
  methods: {
    ...mapActions(['checkAndLoadUserData']),
  },
  serverPrefetch() {
    return this.checkAndLoadUserData({ req: this.$ssrContext.req, isServer: true });
  },
  mounted() {
    if (!this.isUserDataChecked) {
      this.checkAndLoadUserData({ isServer: false });
    }
  }
}
</script>

